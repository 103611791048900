import React from "react";
import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import browserStorageKeys from "../common/browserStorageKeys";
import browserStorage from "../utility/browserStorage";
import deviceBackButtonJioHome from "../utility/deviceBackButtonJioHome";
import handleDeviceBackButton from "../utility/handleDeviceBackButton";
import handleStopDeviceBackButton from "../utility/handleStopDeviceBackButton";
import callNative from "../utility/JioMiniApp/callNative";

const IntroductionPage = React.lazy(() => import("./pages/introductionPage"));
const BenefitPage = React.lazy(() => import("./pages/benefitPage"));
const KnowMorePage = React.lazy(() => import("./pages/knowMorePage"));
const CategoryPage = React.lazy(() => import("./pages/categoryPage"));
const MySchemesPage = React.lazy(() => import("./pages/schemes/mySchemes"));
const MyServicesPage = React.lazy(() => import("./pages/services/myServices"));
const MyJobsPage = React.lazy(() => import("./pages/jobs/myJobs"));
const ProfilePage = React.lazy(() => import("./pages/profilePage"));
const AboutUsPage = React.lazy(() => import("./pages/profilePage/aboutUsPage"));
const Popups = React.lazy(() => import("./elements/popups"));
const LocationPage = React.lazy(() => import("./pages/locationPage"));
const LanguagePage = React.lazy(() => import("./pages/languagePage"));
const SplashPage = React.lazy(() => import("./pages/splashPage"));
const NoSchemesJobsPage = React.lazy(() => import("./pages/noSchemesJobs"));
const IneligiblePage = React.lazy(() => import("./pages/ineligiblePage"));
const ThankYouPage = React.lazy(() => import("./pages/thankYouPage"));
const ReviewPaymentPage = React.lazy(() => import("./pages/reviewPaymentPage"));
const PaymentPage = React.lazy(() => import("./pages/paymentPage"));
const NotificationPage = React.lazy(() =>
  import("./pages/profilePage/notificationPage")
);
const FaqPage = React.lazy(() => import("./pages/profilePage/faqPage"));
const RecommendedPage = React.lazy(() =>
  import("./pages/schemes/recommendedSchemes")
);
const SchemeLoaderPage = React.lazy(() => import("./pages/schemeLoader"));
const ChangeProfilePage = React.lazy(() =>
  import("./pages/profilePage/changeProfilePage")
);
const CreateProfilePage = React.lazy(() =>
  import("./pages/profilePage/createProfilePage")
);
const SurveyPage = React.lazy(() => import("./pages/surveyPage"));
const SchemesPage = React.lazy(() => import("./pages/schemesPage"));
const SchemesHomePage = React.lazy(() => import("./pages/schemes/homePage"));
const ServicesHomePage = React.lazy(() => import("./pages/services/homePage"));
const JobsHomePage = React.lazy(() => import("./pages/jobs/homePage"));
const SearchPage = React.lazy(() => import("./pages/searchPage"));
const ConfirmLocationPage = React.lazy(() =>
  import("./pages/confirmLocationPage")
);
const UpdateLocationPage = React.lazy(() =>
  import("./pages/updateLocationPage")
);
const ReviewSurveyPage = React.lazy(() => import("./pages/reviewSurveyPage"));
const ConfirmSurveyPage = React.lazy(() => import("./pages/confirmSurveyPage"));
const ManageProfilePage = React.lazy(() =>
  import("./pages/profilePage/manageProfile")
);
const Loader = React.lazy(() => import("./elements/loader"));
const NoNetworkPage = React.lazy(() => import("./pages/noNetworkScreen"));
const SomethingWentWrongPage = React.lazy(() => import("./pages/somethingWentWrong"));
const CategorySurveyPage = React.lazy(() =>
  import("./pages/CategorySurveyPage")
);
const SpecificSurveyPage = React.lazy(() =>
  import("./pages/SpecificSurvey")
);
const KycPage = React.lazy(() => import("./pages/kycPage"))
const ApplicationFormPage = React.lazy(() => import("./pages/applicationFormPage"))
const ReviewApplicationDetailsPage = React.lazy(()=>
import("./pages/reviewApplicationDetails")
);
const AccessDeniedPage = React.lazy(()=>import("./pages/accessDenied"));
const  ServerTimeOutPage= React.lazy(()=>import("./pages/serverTimeOut"));
const  ResourceNotAvailablePage= React.lazy(()=>import("./pages/resourceNotAvailable"));
const HelpAndSupportPage = React.lazy(() => import("./pages/profilePage/helpAndSupportPage"));
const SchemeAwareness=  React.lazy(() => import("./pages/SchemeAwareness"));
const SchemeAwarenessThankYou=  React.lazy(() => import("./pages/SchemeAwarenessThankYou"));
const ApplicationStatusPage = React.lazy(()=>import("./pages/applicationStatusPage"));
//Added routed for NuPay Payment integration
const PaymentIntegration = React.lazy(() => import("./pages/NuPayIntegration/NuPayPaymentIntegration"))
const PaymentLoader = React.lazy(() => import("./pages/NuPayIntegration/NuPayPaymentLoader"))
const LoginPage = React.lazy(() => import("./pages/login"))
export default function RoutesMobile() {
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  
  if (
    browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ) === null &&
    pathName !== "/"
  ) {
    navigate("/");
  } else if (
    browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID) === null &&
    pathName !== "/location" &&
    browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)
  ) {
    navigate("/location");
  } else if (
      pathName === "/" &&
      JSON.parse(browserStorage.getItemValue(browserStorageKeys.IS_INTRODUCTION_BUTTON_CLICKED)) === false &&
      browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ) &&
      browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID)
    ) {
      navigate("/introduction");
    }else{
      if(pathName === "/" &&
      browserStorage.getItemValue(browserStorageKeys.ACTIVE_PROFILE) &&
      browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ) &&
      browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID) &&
      JSON.parse(browserStorage.getItemValue(browserStorageKeys.IS_INTRODUCTION_BUTTON_CLICKED)) === true){
        navigate("/home");
      } else if(pathName === "/redirect" &&
      browserStorage.getItemValue(browserStorageKeys.ACTIVE_PROFILE) &&
      browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ) &&
      browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID) &&
      JSON.parse(browserStorage.getItemValue(browserStorageKeys.IS_INTRODUCTION_BUTTON_CLICKED)) === false){
        navigate("/home");
      }
    }

useEffect(() => {
  if (
    pathName === "/" ||
    pathName === "/home" ||
    pathName === "/homejobs" ||
    pathName === "/profile" ||
    pathName === "/services" ||
    pathName === "/location"
  ) {
    callNative({ type: "showHeader" });
  }else {
    callNative({ type: "hideHeader" });
  }
},[pathName])
  
if(pathName === "/reviewSurvey" || 
pathName === "/confirmSurvey"){
  const divTag = document.getElementById("scroll");
  if(divTag){
    window.scrollTo(0,0)
  }
}

/*Condition for handling device back button by redirecting user to EasyGov homepage*/ 
if (pathName && pathName !== "/nuPayPaymentLoader") {
  const navigateUrl = handleDeviceBackButton();
  callNative({ type: "handleWebviewBack", value: false });
  window.handleBackKey = () => {
    navigate(navigateUrl);
  };
}
/*Condition for handling device button taking user to Jio Home screen from scheme, services, jobs, search and profile section*/
if(pathName === "/home" || pathName === "/services" || pathName === "/homejobs"){
  deviceBackButtonJioHome();
}
  return (
    <>
      <Routes>
        <Route path="/" element={<LanguagePage />}></Route>
        <Route path="/selectLanguage" element={<LanguagePage />}></Route>
        <Route path="/home/benefit" element={<BenefitPage />}></Route>
        <Route path="/home/knowmore" element={<KnowMorePage />}></Route>
        <Route path="/home/category" element={<CategoryPage />}></Route>
        <Route
          path="/home/viewAllSchemes"
          element={<RecommendedPage />}
        ></Route>
        <Route path="/myschemes" element={<MySchemesPage />}></Route>
        <Route path="/myservices" element={<MyServicesPage />}></Route>
        <Route path="/myjobs" element={<MyJobsPage />}></Route>
        <Route path="/popups" element={<Popups />}></Route>
        <Route path="/profile" element={<ProfilePage />}></Route>
        <Route path="/aboutus" element={<AboutUsPage />}></Route>
        <Route path="/location" element={<LocationPage />}></Route>
        <Route path="/selectLocation" element={<LocationPage />}></Route>
        <Route path="/introduction" element={<IntroductionPage />}></Route>
        <Route path="/splash" element={<SplashPage />}></Route>
        <Route path="/noschemesjobs" element={<NoSchemesJobsPage />}></Route>
        <Route path="/ineligible" element={<IneligiblePage />}></Route>
        <Route path="/thankyou" element={<ThankYouPage />}></Route>
        <Route path="/reviewPayment" element={<ReviewPaymentPage />}></Route>
        <Route path="/notifications" element={<NotificationPage />}></Route>
        <Route path="/faq" element={<FaqPage />}></Route>
        <Route path="/search" element={<SearchPage />}></Route>
        <Route path="/loader" element={<SchemeLoaderPage />}></Route>
        <Route path="/changeprofile" element={<ChangeProfilePage />}></Route>
        <Route path="/createprofile" element={<CreateProfilePage />}></Route>
        <Route path="/survey" element={<SurveyPage />}></Route>
        <Route path="/categorySurvey" element={<CategorySurveyPage />}></Route>
        <Route path="/specificSurvey" element={<SpecificSurveyPage />}></Route>
        <Route path="/schemes" element={<SchemesPage />}></Route>
        <Route path="/home" element={<SchemesHomePage />}></Route>
        <Route path="/services" element={<ServicesHomePage />}></Route>
        <Route path="/homejobs" element={<JobsHomePage />}></Route>
        <Route
          path="/confirmlocation"
          element={<ConfirmLocationPage />}
        ></Route>
        <Route path="/updatelocation" element={<UpdateLocationPage />}></Route>
        <Route path="/reviewSurvey" element={<ReviewSurveyPage />}></Route>
        <Route path="/confirmSurvey" element={<ConfirmSurveyPage />}></Route>
        <Route path="/manageprofile" element={<ManageProfilePage />}></Route>
        <Route path="/loader1" element={<Loader />}></Route>
        <Route path="/nonetwork" element={<NoNetworkPage />}></Route>
        <Route path="/somethingWentWrong" element={<SomethingWentWrongPage />}></Route>
        <Route path="/kycPage" element={<KycPage />}></Route>
        <Route path="/applicationForm" element={<ApplicationFormPage />}></Route>
        <Route path="/reviewApplicationDetails" element={<ReviewApplicationDetailsPage />}></Route>
        <Route path="/accessDenied" element={<AccessDeniedPage />}></Route>
        <Route path="/serverTimeOut" element={< ServerTimeOutPage/>}></Route>
        <Route path="/resourceNotAvailable" element={<ResourceNotAvailablePage/>}></Route>
        <Route path="/helpAndSupport" element={<HelpAndSupportPage />}></Route>
        <Route path="/home/schemeAwareness" element={<SchemeAwareness />}></Route>
        <Route path="/home/thankYou" element={<SchemeAwarenessThankYou />}></Route>
        <Route path="/applicationStatus" element={<ApplicationStatusPage />}></Route>
        <Route path="/nuPayPayment" element={<PaymentIntegration />}></Route>
        <Route path="/nuPayPaymentLoader" element={<PaymentLoader />}></Route>
        <Route path="/paymentPage" element={<PaymentPage />}></Route>
        <Route path="/login" element={<LoginPage />}></Route>
      </Routes>
    </>
  );
}
